<template>
  <div class="activity-page">
    <!-- <top-box :option="2"></top-box> -->
    <div class="bannar">
      <!-- <img src="../../image/bg.png"> -->
      <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
      <img :src="bannerSrc" v-if="bannerType" />
      <video
        v-else
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <div class="overlay"></div>
      <div class="bannar-info">
        <div class="bannar-title">赛事活动</div>
        <div class="bannar-detail">{{ bannerTitle }}</div>
      </div>
    </div>
    <main>
      <div class="main-time">
        <div class="main-time-content">
          <div class="time-left" @click="optionTime('sub')">
            <div class="time-left-bg"></div>
          </div>
          <div
            @click="optionTime(index)"
            :class="optionIdx == index ? 'time-year active' : 'time-year'"
            v-for="(item, index) in optionTimeList"
            :key="index"
          >
            {{ item }}
            <el-select
              v-if="optionIdx == index"
              v-model="timeValue"
              @change="changeClick"
            >
              <el-option
                v-for="(item, index) in timeList"
                :key="index"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <!-- <div class="time-year">2021</div>
                    <div class="time-year">2022</div>
                    <div class="time-year active">2023</div> -->
          <div class="time-right" @click="optionTime('add')">
            <div class="time-right-bg"></div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <div
          v-for="(item, index) in activityList"
          :key="index"
          @click="activityDetails(item.id)"
          :style="{
            paddingBottom: index == activityList.length - 1 ? '0px' : '80px',
          }"
          class="main-content-item"
        >
          <div class="main-content-item-time">
            <span>{{ item.endTime }}</span>
            <div class="cicle">
              <p></p>
            </div>
            <div class="line"></div>
          </div>
          <div class="item-img">
            <img :src="item.imgSrc" :alt="item.name" :title="item.name" />
          </div>
          <!-- <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/186ee48122584540a96f86749713a198_mergeImage.png"> -->
          <div class="main-content-item-info">
            <p class="title">{{ item.name }}</p>
            <div class="info-content">
              <p>活动人群：{{ item.activityObject }}</p>
              <p>
                报名时间：{{ item.enrollBeginTime }}至{{ item.enrollEndTime }}
              </p>
              <p>活动时间：{{ item.beginTime }}至{{ item.endTime }}</p>
              <p>活动人数：{{ item.totalNum }}人</p>
              <!-- <p style="height:40px">
                               {{item.detail}}
                            </p> -->
              <p>提供服务：{{ item.provideServices || "暂无" }}</p>
            </div>
            <div class="info-status">
              <p>
                报名费用： <span>￥{{ item.price }}</span>
              </p>
              <span
                @click.stop="openClick(item.id)"
                v-if="item.enrollStatus == 1"
                >立即报名</span
              >
              <span
                v-else-if="item.enrollStatus == 0"
                style="backgroundcolor: #dddddd; color: #999"
                >即将开始</span
              >
              <span
                @click.stop="openClick(item.id)"
                v-else
                style="backgroundcolor: #dddddd; color: #999"
                >已结束</span
              >
            </div>
          </div>
        </div>
        <!-- <div v-if="activityList.length == 0" class="empty">
                    暂无内容
                </div> -->
        <div class="empty" v-if="activityList.length == 0">
          <div class="empty-bg"></div>
          <div class="empty-content">暂无数据~</div>
        </div>
      </div>
      <div class="activity-overlay" v-if="overlayShow" @click="closeClick">
        <div class="overlay-box">
          <div class="overlay-title">微信小程序登录</div>
          <div class="overlay-img">
            <vueQr
              :margin="10"
              :size="240"
              :whiteMargin="true"
              :logoMargin="3"
              :logoCornerRadius="20"
              :text="wxHref"
            ></vueQr>
          </div>
          <div class="overlay-app">
            请使用微信扫描二维码登录<br />“天府国际赛道小程序”
          </div>
          <div class="overlay-close" @click.stop="closeClick"></div>
        </div>
      </div>
    </main>
    <!-- <bottom-box></bottom-box> -->
  </div>
</template>
<script>
import topBox from "../component/top.vue";
import bottomBox from "../component/bottom.vue";
import { getBanner, getActivity } from "../../../api/homeApi";
import vueQr from "vue-qr";
export default {
  components: {
    topBox,
    bottomBox,
    vueQr,
  },
  data() {
    return {
      bannerSrc: "",
      bannerType: 1, //1.图片 0.视频
      bannerTitle: "",
      activityList: [],
      optionIdx: 3,
      optionTimeList: [],
      timeValue: 0,
      timeList: [
        { value: 0, label: "全部" },
        { value: 1, label: "1月" },
        { value: 2, label: "2月" },
        { value: 3, label: "3月" },
        { value: 4, label: "4月" },
        { value: 5, label: "5月" },
        { value: 6, label: "6月" },
        { value: 7, label: "7月" },
        { value: 8, label: "8月" },
        { value: 9, label: "9月" },
        { value: 10, label: "10月" },
        { value: 11, label: "11月" },
        { value: 12, label: "12月" },
      ],
      date: null,
      year: "2023-01-01",
      pageSize: 1,
      lastSize: 1,
      totalPage: 0,
      overlayShow: false,
      overlayHeight: 0,
      wxHref: window.location.href,
    };
  },
  watch: {
    pageSize: {
      handler(val) {
        if (val > this.lastSize && val <= this.totalPage) {
          this.pageSize = val;
          this.getActivity();
        }
      },
    },
  },
  created() {
    this.getBanner();
    let newYear = new Date().getFullYear();
    this.year = newYear + "-01-01";
    this.getActivity();
    for (var i = newYear - 3; i <= newYear; i++) {
      if (i >= 2023) {
        this.optionTimeList.push(i);
      }
    }
    this.optionIdx = this.optionTimeList.length - 1;
    window.addEventListener("scroll", () => {
      var width = document.documentElement.clientWidth;
      let scale = 1;
      var scrollTop = document.documentElement.scrollTop;
      if (width < 1400) {
        scale = width / 1400;
        scrollTop = scrollTop / scale;
      }
      this.pageSize = Math.floor(scrollTop / 600) || 1;
    });
  },
  destroyed() {
    // window.removeEventListener('scroll')
  },
  methods: {
    activityDetails(id) {
      this.$router.push(`/activity-details/${id}`);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    getBanner() {
      getBanner({
        plate: 2,
      }).then((res) => {
        if (res.code == 0) {
          if (res.infos.length > 0) {
            if (this._isMobile()) {
              this.bannerType = 1;
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].mobileImage)[0].raw
              );
            } else {
              let arr = JSON.parse(res.infos[0].image);
              if (
                [
                  "image/png", // .png
                  "image/jpeg", // .jpg .jpeg
                  "image/x-ms-bmp", // .bmp
                  "image/gif", // .gif
                  "image/tiff", // .tif .tiff
                ].includes(arr[0].type)
              ) {
                this.bannerType = 1;
              } else {
                this.bannerType = 0;
              }
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].image)[0].raw
              );
            }
            this.bannerTitle = res.infos[0].content;
          }
        }
      });
    },
    optionTime(val) {
      if (typeof val == "number") {
        this.optionIdx = val;
      } else {
        if (val == "sub") {
          if (this.optionIdx == 0) return;
          this.optionIdx -= 1;
        } else {
          if (this.optionIdx == 3) return;
          this.optionIdx += 1;
        }
      }
      this.year = this.optionTimeList[this.optionIdx] + "-01-01";
      this.date = null;
      this.timeValue = 0;
      this.pageSize = 1;
      this.getActivity();
    },
    getActivity() {
      let obj = {};
      if (this.date) {
        obj = {
          limit: 4,
          page: this.pageSize,
          date: this.date,
        };
      } else {
        obj = {
          limit: 4,
          page: this.pageSize,
          year: this.year,
        };
      }
      this.lastSize = obj.page;
      getActivity(obj).then((res) => {
        if (res.code == 0) {
          this.totalPage = res.page.totalPage;
          if (this.pageSize == 1) this.activityList = [];
          this.activityList = this.activityList.concat(res.page.list);
          res.page.list.forEach((item) => {
            let str = item.details
              .replace(/<[^<>]+>/g, "")
              .replace(/&nbsp;/gi, "");
            item.detail = str;
            let arr = item.endTime.split("-");
            let arr2 = item.beginTime.split("-");
            let arr3 = item.enrollBeginTime.split("-");
            let arr4 = item.enrollEndTime.split("-");
            item.endTime = arr[1] + "月-" + arr[2] + "日";
            item.beginTime = arr2[1] + "月-" + arr2[2] + "日";
            item.enrollBeginTime = arr3[1] + "月-" + arr3[2] + "日";
            item.enrollEndTime = arr4[1] + "月-" + arr4[2] + "日";
            item.imgSrc = this.formatImageUrl(JSON.parse(item.image)[0].raw);
            // return item
          });
        }
      });
    },
    changeClick(value) {
      if (value == 0) {
        this.date = null;
      } else {
        let mouth = value > 9 ? value + "-01" : "0" + value + "-01";
        this.date = this.optionTimeList[this.optionIdx] + "-" + mouth;
        this.pageSize = 1;
      }
      this.getActivity();
    },
    openClick() {
      this.overlayShow = true;
      this.disabledMouseWheel();
      this.$store.commit("common/mutationOverlayStatus", true);
      var width = document.documentElement.clientWidth;
      let scale = 1;
      var scrollTop = document.documentElement.scrollTop + 300;
      if (width < 1400) {
        scale = width / 1400;
        scrollTop = scrollTop / scale;
      }
      setTimeout(() => {
        var box = document.querySelector(".overlay-box");
        // box.style.top = scrollTop + 'px'
        var overlay = document.querySelector(".activity-overlay");
        var height = document.documentElement.scrollHeight / scale;
        // overlay.style.height = height + 'px'
      });
    },
    closeClick() {
      this.overlayShow = false;
      this.$store.commit("common/mutationOverlayStatus", false);
      this.cancelDisMouseWheel();
    },
    // 禁止滚动
    disabledMouseWheel() {
      document.addEventListener("DOMMouseScroll", this.scrollFunc, {
        passive: false,
      });
      document.addEventListener("mousewheel", this.scrollFunc, {
        passive: false,
      });
    },
    // 取消禁止滚动
    cancelDisMouseWheel() {
      document.removeEventListener("DOMMouseScroll", this.scrollFunc, {
        passive: false,
      });
      document.removeEventListener("mousewheel", this.scrollFunc, {
        passive: false,
      });
    },
    scrollFunc(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and(max-width: 900px) {
  .activity-page {
    width: 100%;
    // height: 100%;
    // background: #ccc;
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      margin-top: 20px;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        // height: 100%;
        width: 100%;
        vertical-align: middle;
        height: 30vw;
      }
      .bannar-info {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        padding: 0 20px;
        text-align: center;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    .main-time {
      height: 0.8rem;
      width: 100%;
      background-color: #eeeeee;
      .main-time-content {
        // width: 12rem;
        padding: 0 20px;
        height: 100%;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        .time-left,
        .time-right {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          // border: 1px solid #666;
        }
        .time-left {
          .time-left-bg {
            height: 100%;
            width: 100%;
            background: url("../../../image/down-left.png") center center
              no-repeat;
            background-size: 100%;
          }
        }
        .time-right {
          .time-right-bg {
            height: 100%;
            width: 100%;
            background: url("../../../image/down-right.png") center center
              no-repeat;
            background-size: 100%;
          }
        }
        .time-year {
          font-size: 18px;
          color: #666;
        }
        .time-year.active {
          font-size: 36px;
        }
      }
    }
    .activity-overlay {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      z-index: 99;
      .overlay-box {
        position: absolute;
        width: 100%;
        top: calc(50vh - 100px);
        // text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        z-index: 999;
        .overlay-title {
          font-size: 28px;
        }
        .overlay-img {
          height: 240px;
          width: 240px;
          background-color: #fff;
          margin: 20px;
        }
        .overlay-app {
          font-size: 20px;
          width: 280px;
          border-radius: 30px;
          background-color: #ee6a23;
          padding: 5px 0;
          text-align: center;
        }
        .overlay-close {
          height: 50px;
          width: 50px;
          background: url("../../../image/close2.png") center center no-repeat;
          background-size: 100%;
          margin-top: 15px;
          cursor: pointer;
        }
      }
    }
    .main-content {
      padding: 0.8rem 20px;
      // width: 12rem;
      margin: 0 auto;

      .main-content-item {
        margin-left: 1.4rem;
        width: calc(100% - 1.4rem);
        // width: 10.6rem;
        box-sizing: border-box;
        display: flex;
        position: relative;
        padding-bottom: 80px;
        flex-wrap: wrap;
        .main-content-item-time {
          position: absolute;
          top: 0;
          left: -1.4rem;
          display: flex;
          height: 100%;
          margin-top: 10px;
          span {
            display: block;
            width: 0.7rem;
            height: 0.2rem;
            background-color: #ee6a23;
            color: #fff;
            font-size: 12px;
            text-align: center;
            line-height: 0.2rem;
            margin-right: 20px;
          }
          .cicle {
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            border: 1px solid #ee6a23;
            position: relative;
            p {
              position: absolute;
              top: 3px;
              left: 3px;
              margin: 0;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: #ee6a23;
            }
          }
          .line {
            height: calc(100% - 21px);
            width: 1px;
            background-color: #ee6a23;
            position: absolute;
            top: 21px;
            left: 100px;
          }
        }

        img {
          height: 3.8rem;
          // width: 100%;
          width: 6.8rem;
          vertical-align: bottom;
        }
        .main-content-item-info {
          padding: 0.5rem 0.4rem;
          display: flex;
          flex-direction: column;
          background-color: #f1f1f1;
          border: 1px solid #e6e6e6;
          font-size: 12px;
          color: #666;
          justify-content: space-between;
          width: 100%;
          box-sizing: border-box;
          .title {
            font-size: 26px;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            margin: 0;
          }
          .info-content {
            flex: 1;
            p {
              margin: 20px 0;
            }
            p:nth-child(4) {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .info-status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span:nth-child(2) {
              width: 140px;
              background-color: #ee6a23;
              font-size: 18px;
              color: #fff;
              text-align: center;
              line-height: 46px;
              cursor: pointer;
            }
            p {
              span {
                color: #ee6a23;
                font-size: 16px;
              }
            }
          }
        }
      }
      .main-content-item:last-child {
        .main-content-item-time {
          .line {
            height: calc(100% - 31px);
          }
        }
      }
    }
  }
}
@media screen and(min-width: 900px) {
  .activity-page {
    width: 100%;
    // height: 100%;
    // background: #ccc;
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      img {
        // height: 100%;
        width: 100%;
        vertical-align: middle;
        height: 550px;
      }
      .bannar-info {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    .main-time {
      height: 0.8rem;
      width: 100%;
      background-color: #eeeeee;
      .main-time-content {
        width: 12rem;
        height: 100%;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        .time-left,
        .time-right {
          height: 0.3rem;
          width: 0.3rem;
          border-radius: 50%;
          cursor: pointer;
          // border: 1px solid #666;
        }
        .time-left {
          .time-left-bg {
            height: 100%;
            width: 100%;
            background: url("../../../image/down-left.png") center center
              no-repeat;
            background-size: 100%;
          }
        }
        .time-left:hover {
          .time-left-bg {
            height: 100%;
            width: 100%;
            background: url("../../../image/down-left2.png") center center
              no-repeat;
            background-size: 100%;
          }
        }
        .time-right {
          .time-right-bg {
            height: 100%;
            width: 100%;
            background: url("../../../image/down-right.png") center center
              no-repeat;
            background-size: 100%;
          }
        }
        .time-right:hover {
          .time-right-bg {
            height: 100%;
            width: 100%;
            background: url("../../../image/down-right2.png") center center
              no-repeat;
            background-size: 100%;
          }
        }

        .time-year {
          font-size: 18px;
          color: #666;
          cursor: pointer;
        }
        // .time-year:hover {
        //     font-size: 18px;
        //     color: #666;
        //     cursor: pointer;
        //     font-size: 36px;
        // }
        .time-year.active {
          font-size: 36px;
        }
      }
    }
    .main-content {
      padding: 0.8rem 0;
      width: 12rem;
      margin: 0 auto;

      .main-content-item {
        margin-left: 1.4rem;
        width: 10.6rem;
        box-sizing: border-box;
        display: flex;
        position: relative;
        padding-bottom: 80px;
        .main-content-item-time {
          position: absolute;
          top: 0;
          left: -1.4rem;
          display: flex;
          height: 100%;
          margin-top: 10px;
          span {
            display: block;
            width: 0.7rem;
            height: 0.2rem;
            background-color: #ee6a23;
            color: #fff;
            font-size: 12px;
            text-align: center;
            line-height: 0.2rem;
            margin-right: 20px;
          }
          .cicle {
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            border: 1px solid #ee6a23;
            position: relative;
            p {
              position: absolute;
              top: 3px;
              left: 3px;
              margin: 0;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: #ee6a23;
            }
          }
          .line {
            height: calc(100% - 21px);
            width: 1px;
            background-color: #ee6a23;
            position: absolute;
            top: 21px;
            left: 100px;
          }
        }
        .item-img {
          // height: 3.8rem;
          width: 6.5rem;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            vertical-align: bottom;
            transition: 0.2s linear;
          }
        }
        .item-img:hover {
          img {
            transform: scale(1.08);
            // transition: .5s linear;
          }
        }
        .main-content-item-info {
          padding: 0.5rem 0.4rem;
          display: flex;
          flex-direction: column;
          background-color: #f1f1f1;
          border: 1px solid #e6e6e6;
          font-size: 12px;
          color: #666;
          justify-content: space-between;
          width: 4.1rem;
          box-sizing: border-box;
          .title {
            font-size: 26px;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            margin: 0;
          }
          .info-content {
            flex: 1;
            p {
              margin: 20px 0;
            }
            p:nth-child(4) {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .info-status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span:nth-child(2) {
              width: 140px;
              background-color: #ee6a23;
              font-size: 18px;
              color: #fff;
              text-align: center;
              line-height: 46px;
              cursor: pointer;
            }
            p {
              span {
                color: #ee6a23;
                font-size: 16px;
              }
            }
          }
        }
      }
      .main-content-item:last-child {
        .main-content-item-time {
          .line {
            height: calc(100% - 31px);
          }
        }
      }
    }
    .activity-overlay {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      z-index: 99;
      .overlay-box {
        position: fixed;
        width: 100%;
        top: calc(50vh - 100px);
        // text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        z-index: 999;
        .overlay-title {
          font-size: 24px;
        }
        .overlay-img {
          height: 240px;
          width: 240px;
          background-color: #fff;
          margin: 20px;
        }
        .overlay-app {
          font-size: 14px;
          width: 240px;
          border-radius: 25px;
          background-color: #ee6a23;
          padding: 5px 0;
          text-align: center;
        }
        .overlay-close {
          height: 30px;
          width: 30px;
          background: url("../../../image/close2.png") center center no-repeat;
          background-size: 100%;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
/deep/.el-select-dropdown {
  margin-top: 0px;
}
/deep/.el-select {
  width: 60px;
  .el-input {
    .el-input__inner {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
}
.el-scrollbar {
  .el-select-dropdown__item.selected {
    color: #ee6a23;
  }
}
.empty {
  width: 100%;
  height: 500px;
  color: #333;
  font-size: 28px;
  text-align: center;
}
</style>